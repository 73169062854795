<template>
    <div class="">
      <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
        <div class="card-table-body ifon-add-title-block">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="caz-blocks-sarcho-title">
                <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> {{ $t("message.sms") }} </div>
                <div class="block-sarche">
                  <div class="header__search">
  
                     <crm-input
                          :size="'small'"
                          :className="'w100'"
                          :class="mode ? 'input__day' : 'input__night'"
                          v-model="filterForm.search"
                          :icon="'el-icon-search'"
                      ></crm-input>
                  </div>
                </div>
              </div>
            </el-col>

            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="flex-style text-right">
                <div style="display:flex; float: right;" class="text-right">
                    <div :class="mode ? 'button__settingsday' : 'button__settingsnight'">
                        <el-button @click="sendOrSaveMessage()" :loading="loadingButton" size="small" type="primary" class="mr-2 btu-color pl-2 pr-2">
                            {{ $t("message.sendOrSaveMessage") }}
                        </el-button>
                    </div>
                </div>
            </el-col>
          </el-row>
        </div>
        <!-- START FORM  -->
        <div class="app-modal__body pb-0">
            <div class="timeline-items__right rounded-sm w-100 p-4" v-loading="loadingButton">
                <el-row :gutter="20" v-if="form.sending_type != 'phones' && form.message_receivers && form.message_receivers.length > 0">
                    <el-col :span="12" v-if="form.message_receivers.includes('clients')">
                        <h4> 
                            {{ $t('message.sms_recieving_clients_count') }} :
                            {{ form.sending_type == 'all' ? reciversCount.clients_count : form.send_to.clients.length }} 
                        </h4>
                    </el-col>
                    <el-col :span="12" v-if="form.message_receivers.includes('users')">
                        <h4> 
                            {{ $t('message.sms_recieving_users_count') }} :
                            {{ form.sending_type == 'all' ? reciversCount.users_count : form.send_to.users.length }} 
                        </h4>
                    </el-col>
                </el-row>
                <el-row :gutter="20" v-if="form.sending_type == 'phones'">
                    <el-col :span="24">
                        <h4> 
                            {{ $t('message.sms_recieving_phones_count') }} : {{ form.send_to.phones.length }} 
                        </h4>
                    </el-col>
                </el-row>
                <el-form ref="form" :model="form" :rules="rules" label-position="top">
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <el-form-item prop="sending_type" :label="$t('message.sending_type')">
                                <el-radio-group v-model="form.sending_type" @change="sendingTypeChange" class="w-100">
                                    <el-radio-button label="all"> {{ $t('message.all') }} </el-radio-button>
                                    <el-radio-button label="selected"> {{ $t('message.selected') }} </el-radio-button>
                                    <el-radio-button label="phones"> {{ $t('message.phone_numbers') }} </el-radio-button>
                                </el-radio-group>
                            </el-form-item>   
                        </el-col>
                        <el-col :span="8" v-if="form.sending_type != 'phones'">
                            <el-form-item prop="message_receivers" :label="$t('message.message_receivers')">
                                <el-checkbox-group v-model="form.message_receivers" @change="sendingRecieversChange"  class="w-100">
                                    <el-checkbox-button label="clients"> {{ $t('message.clients') }} </el-checkbox-button>
                                    <el-checkbox-button label="users"> {{ $t('message.users') }} </el-checkbox-button>
                                </el-checkbox-group>
                            </el-form-item>   
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="sending_date" :label="$t('message.sending_date')">
                                <el-date-picker
                                    class="w-100"
                                    format="yyyy-MM-dd HH:mm" 
                                    value-format="yyyy-MM-dd HH:mm"
                                    v-model="form.sending_date"
                                    :picker-options="datePickerOptions"
                                    default-time="10:00:00"
                                    type="datetime">
                                </el-date-picker>
                            </el-form-item>   
                        </el-col>
                        <el-col :span="8" v-if="form.sending_type == 'phones'">
                            <el-form-item prop="add_phone_number" :label="$t('message.add_phone_number')">
                                <el-button @click="addPhoneInput()" type="primary" icon="el-icon-plus">{{ $t('message.add_phone_number') }}</el-button>
                            </el-form-item>   
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" v-if="form.sending_type == 'phones'">
                        <el-col :span="8" v-for="(phone, index) in form.send_to.phones" :key="'phone_'+index">
                            <div class="d-flex mm_items_bottom">
                                <crm-phone-input
                                    class="m-bor w-100"
                                    :showCountries="['UZ']"
                                    :inputValue="form.send_to.phones[index].number" 
                                    v-model="form.send_to.phones[index].number"
                                    @getPhoneStatus="getPhoneStatus($event, index)"
                                    :form_prop="'phone'"
                                    :country_code="form.send_to.phones[index].country_code"
                                ></crm-phone-input>
                                <el-button class="mm_mb19 ml-2" @click="removePhoneInput(index)" icon="el-icon-delete"></el-button>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" v-if="form.sending_type == 'selected' && form.message_receivers.includes('clients')">
                        <el-col :span="12">
                            <el-form-item prop="companies" :label="$t('message.companies')">
                                <select-company
                                    :size="'large'"
                                    :placeholder="$t('message.companies')"
                                    :id="form.send_to.companies"
                                    v-model="form.send_to.companies"
                                    :multiple="true"
                                >
                                </select-company>
                            </el-form-item>   
                        </el-col>

                        <el-col :span="12">
                            <el-form-item :label="$t('message.clients')"
                                :prop="'send_to.clients'"
                                :rules="{ required: true, message: $t('message.please_select_input', {input: $t('message.clients')}), trigger: 'change' }"
                            >
                                <select-client
                                    :size="'large'"
                                    :placeholder="$t('message.clients')"
                                    :id="form.send_to.clients"
                                    :companies="form.send_to.companies"
                                    v-model="form.send_to.clients"
                                    :multiple="true"
                                >
                                </select-client>
                            </el-form-item>   
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" v-if="form.sending_type == 'selected' && form.message_receivers.includes('users')">
                        <el-col :span="12">
                            <el-form-item prop="departments" :label="$t('message.departments')">
                                <select-department
                                    v-model="form.send_to.departments"
                                    :id="form.send_to.departments"
                                    :filial_ids="form.send_to.filials"
                                    :size="'large'"
                                    :multiple="true"
                                >
                                </select-department>
                            </el-form-item>   
                        </el-col>

                        <el-col :span="12">
                            <el-form-item :label="$t('message.users')"
                                :prop="'send_to.users'"
                                :rules="{ required: true, message: $t('message.please_select_input', {input: $t('message.users')}), trigger: 'change' }"
                            >
                                <multi-select-users
                                    :size="'large'"
                                    :department_ids="form.send_to.departments"
                                    v-model="form.send_to.users"
                                    :user_ids="form.send_to.users"
                                    :collapse_tags="true"
                                >
                                </multi-select-users>  
                            </el-form-item>   
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item prop="message" :label="$t('message.message')">
                                <el-input
                                    type="textarea"
                                    :autosize="{ minRows: 2, maxRows: 10}"
                                    :placeholder="$t('message.message')"
                                    maxlength="255"
                                    show-word-limit
                                    v-model="form.message">
                                </el-input>
                            </el-form-item>   
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>
        <!-- END FORM  -->
        <div class="card-table-body ifon-add-title-block">
            <el-row :gutter="20">
                <el-col :span="24" class="flex-style text-right">
                    <crm-create-and-column-settings
                        :permission="$options.name"
                        :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                        :create_button="false"
                        :columns="columns"
                        @c-change="updateColumn"
                    >
                    </crm-create-and-column-settings>
                </el-col>
            </el-row>
        </div>
  
        <div class="card-table-header table-crm-smart">
            <table
                class="table-my-code table-bordered"
                :class="mode ? 'table__myday' : 'table__mynight'"
                v-loading="loadingData"
            >
                <thead>
                    <tr>
                        <th class="w50p" v-if="columns.id.show">
                            {{ columns.id.title }}
                        </th>

                        <th v-if="columns.sending_type.show">
                            {{ columns.sending_type.title }}
                        </th>

                        <th v-if="columns.message_receivers.show">
                            {{ columns.message_receivers.title }}
                        </th>

                        <th v-if="columns.message.show">
                            {{ columns.message.title }}
                        </th>

                        <th v-if="columns.sending_date.show">
                            {{ columns.sending_date.title }}
                        </th>

                        <th v-if="columns.created_at.show">
                            {{ columns.created_at.title }}
                        </th>

                        <th v-if="columns.updated_at.show">
                            {{ columns.updated_at.title }}
                        </th>

                        <th v-if="columns.settings.show">
                            {{ columns.settings.title }}
                        </th>
                    </tr>

                    <tr class="filter_sorche">
                        <th v-if="columns.id.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="columns.id.title"
                                class="id_input"
                            ></el-input>
                        </th>

                        <th v-if="columns.sending_type.show">
                            <crm-input
                                :placeholder="columns.sending_type.title"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                v-model="filterForm.sending_type"
                            ></crm-input>
                        </th>

                        <th v-if="columns.message_receivers.show">
                            <crm-input
                                :placeholder="columns.message_receivers.title"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                v-model="filterForm.message_receivers"
                            ></crm-input>
                        </th>

                        <th v-if="columns.message.show">
                            <crm-input
                                :placeholder="columns.message.title"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                v-model="filterForm.message"
                            ></crm-input>
                        </th>

                        <th v-if="columns.sending_date.show">
                            <crm-date-picker
                                :placeholder="columns.sending_date.title"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                v-model="filterForm.sending_date"
                            ></crm-date-picker>
                        </th>

                        <th v-if="columns.created_at.show">
                            <crm-date-picker
                                :placeholder="columns.created_at.title"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                v-model="filterForm.created_at"
                            ></crm-date-picker>
                        </th>

                        <th v-if="columns.updated_at.show">
                            <crm-date-picker
                                :placeholder="columns.updated_at.title"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                v-model="filterForm.updated_at"
                            ></crm-date-picker>
                        </th>

                        <th
                            class="settinW"
                            v-if="columns.settings.show"
                        ></th>
                    </tr>
                </thead>

                <transition-group name="flip-list" tag="tbody">
                    <tr v-for="sms_message in list" :key="sms_message.id" class="cursor-pointer">

                        <td v-if="columns.id.show">
                            {{ sms_message.id }}
                        </td>

                        <td v-if="columns.sending_type.show">
                            {{ $t('message.' + sms_message.sending_type) }}
                        </td>

                        <td v-if="columns.message_receivers.show">
                            <span v-for="reciver in sms_message.message_receivers">
                                {{ $t('message.' + reciver) }}
                            </span>
                        </td>

                        <td v-if="columns.message.show">
                            {{ sms_message.message }}
                        </td>

                        <td v-if="columns.sending_date.show">
                            {{ sms_message.sending_date }}
                        </td>

                        <td v-if="columns.created_at.show">
                            {{ sms_message.created_at }}
                        </td>

                        <td v-if="columns.updated_at.show">
                            {{ sms_message.updated_at }}
                        </td>

                        <td v-if="columns.settings.show" class="settings-td">
                            <crm-settings
                                v-if="sms_message.can_delete"
                                :name="$options.name"
                                :model="sms_message"
                                :actions="actions"
                                :permissionDestroy="'phoneMessageSMS.delete'"
                                @delete="destroy"
                            ></crm-settings>
                        </td>
                    </tr>
                </transition-group>
            </table>
            <div class="my___pagination">
                <crm-pagination
                    @c-change="updatePagination"
                    :class="mode ? 'pagination__day' : 'pagination__night'"
                    :pagination="pagination"
                ></crm-pagination>
            </div>
        </div>
      </div>
    </div>
</template>
  
<script>
  // @ is an alias to /src
import list from "@/utils/mixins/list";
import { mapGetters, mapActions } from "vuex";
import SelectDepartment from '@/components/inventory/select-department';
import MultiSelectUsers from '@/components/multiSelects/multi-select-users';
import selectCompany from "@/components/inventory/select-company";
import selectClient from "@/components/inventory/select-client";

export default {
    name: "sms",
    mixins: [list],
    components: {
        MultiSelectUsers,
        selectClient,
        selectCompany,
        SelectDepartment
    },

    data() {
        return {
            form: {
                id : '',  
                sending_type: 'selected', 
                message_receivers: ['clients'], 
                sending_date: '',
                send_to: {
                    companies: [], 
                    clients: [], 
                    departments: [], 
                    users: [], 
                    phones: [],
                },
                message: '',
                created_at: '',
                updated_at: ''  
            },
            new_phone: {
                number: '+998',
                country_code: 'UZ',
                is_valid: false
            },
            datePickerOptions: {
                disabledDate(time) {
                    const currentDate = new Date();
                    currentDate.setHours(0, 0, 0, 0);
                    return time.getTime() < currentDate.getTime();
                },
            },
            loadingButton: false
        };
    },

    created() {
        this.getReciversCount();
    },

    computed: {
        ...mapGetters({
            rules: "phoneMessage/rules",
            reciversCount: "phoneMessage/reciversCount",
            model: "phoneMessage/model",
            list: "phoneMessage/list",
            columns: "phoneMessage/columns",
            pagination: "phoneMessage/pagination",
            filter: "phoneMessage/filter",
            sort: "phoneMessage/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["delete"];
        },
        totalMessageReceivers(){
            if(this.form.sending_type == 'all' && this.form.message_receivers.includes('clients'))
            {
                return this.reciversCount.clients_count;
            }
            else if(this.form.sending_type == 'all' && this.form.message_receivers.includes('users')) 
            {
                return this.reciversCount.users_count;
            }
            else if(this.form.sending_type == 'all') 
            {
                return this.reciversCount.clients_count + this.reciversCount.users_count;
            }
            else if(this.form.sending_type == 'selected') 
            {
                return this.form.send_to.clients.length + this.form.send_to.users.length;
            }
            else
            {
                return this.form.send_to.phones.length;
            }
        }
    },

    methods: {
        ...mapActions({
            save: "phoneMessage/store",
            getReciversCount: "phoneMessage/getReciversCount",
            updateList: "phoneMessage/index",
            setPagination: "phoneMessage/setPagination",
            updateSort: "phoneMessage/updateSort",
            updateFilter: "phoneMessage/updateFilter",
            updateColumn: "phoneMessage/updateColumn",
            updatePagination: "phoneMessage/updatePagination",
            show: "phoneMessage/show",
            empty: "phoneMessage/empty",
            delete: "phoneMessage/destroy",
            refreshData: "phoneMessage/refreshData",
        }),
        sendingTypeChange(value){
            if(value == 'phones'){
                this.form.message_receivers = [];
            }else if(value != 'phones'){
                this.form.send_to.phones = [];
            }
        },
        
        sendingRecieversChange(val) {
            if(!val.includes('clients')){
                this.form.send_to.clients = [];
            }
            if(!val.includes('users')){
                this.form.send_to.users = [];
            }
        },
        getPhoneStatus({is_valid, countryCode}, index){
            this.form.send_to.phones[index].is_valid = is_valid;
            this.form.send_to.phones[index].country_code = countryCode;
        },
        reloadForm(){
            this.form = JSON.parse(JSON.stringify(this.model));
        },
        saveMessage(){
            this.loadingButton = true;
            this.save(this.form)
                .then((res) => {
                    this.loadingButton = false;
                    this.$alert(res);
                    this.reloadForm();
                    this.fetchData();
                })
                .catch((err) => {
                    this.loadingButton = false;
                    this.$alert(err);
                });
        },

        sendOrSaveMessage(){
            let invalid_phones = this.form.send_to.phones.filter(el => el.is_valid == false);
            invalid_phones = invalid_phones.map(function(el) {
                return el.number;
            });
            this.$refs["form"].validate((valid) => {
                if(invalid_phones.length > 0){
                    this.$notify({
                        title: this.$t('message.error_m'),
                        type: "warning",
                        offset: 130,
                        message: this.$t('message.invalid_phones_are_detected_check_again', {numbers: invalid_phones})
                    });
                }else if (this.form.sending_type == 'phones' && this.form.send_to.phones.length == 0) {
                
                    this.$notify({
                        title: this.$t('message.error_m'),
                        type: "warning",
                        offset: 130,
                        message: this.$t('message.there_is_no_phones')
                    })
                
                }else if (valid) {
                    this.$confirm(
                        this.$t('message.total_message_sms_receivers') + ': ' + this.totalMessageReceivers,
                        this.$t("message.warning"), 
                        {
                            confirmButtonText: this.$t("message.yes"),
                            cancelButtonText: this.$t("message.no"),
                            type: "warning"
                        }
                    )
                    .then(() => {
                        this.saveMessage();
                    })
                    .catch(() => {
                        this.$message({
                            type: "warning",
                            message: this.$t("message.operation_canceled")
                        });
                    });
                }
            });
        },
        addPhoneInput(){
            this.form.send_to.phones.push(JSON.parse(JSON.stringify(this.new_phone)));
        },
        removePhoneInput(index){
            this.form.send_to.phones.splice(index, 1);
        }

    },
    beforeRouteLeave (to, from, next) {
        this.reloadForm();
        this.$store.commit('phoneMessage/EMPTY_LIST');
        next()
    },
};
</script>
<style>
.mm_items_bottom {
    width: 100%;
    align-items: flex-end;
}
.mm_mb19 {
    margin-bottom: 19px;
}

</style>